import React, {Fragment} from 'react';
import FootballEventCell from "../components/FootballEventCell";
import {fixupTime, footballResultClass, getNewDate} from "../Utils";
import {Table, Paper, TableCell, TableRow, TableBody, TableContainer} from "@mui/material";

function cellClass(row, myPicks) {
    const myPick = myPicks.find((pick) => pick.Pick === row.team1 || pick.Pick === row.team2);
    if (myPick) {
        return footballResultClass(myPick.Result);
    }
    return '';
}

function scoresSort(a,b) {
    const aFinal = (a.started === 1 && a.timeLeft === 0);
    const bFinal = (b.started === 1 && b.timeLeft === 0);

    if (aFinal !== bFinal)
        return aFinal ? 1 : -1;

    if (a.started !== b.started) {
        if (a.inProgress !== b.inProgress) {
            return a.inProgress ? -1 : 1;
        }
        else {
            return a.started ? -1 : 1;
        }
    }

    if (a.day !== b.day) {
        const aDate = getNewDate(a.day + "-00:00"), bDate = getNewDate(b.day + "-00:00");
        return aDate - bDate;
    }

    return a.team1.localeCompare(b.team1);
}

function dayFormat(day) {
    return fixupTime(day.getHours(), day.getMinutes());
}

export default function ScoresTable(scoresData, week, myPicks, notificationSupport, onNotificationUpdate) {
    return (
        <Fragment>
            <div className="FootballStandingsGridItem">
                <h4>Week {week} Scores</h4>
                <TableContainer component={Paper} className="FootballStandingsGridItemTable">
                    <Table size="small" className="FootballScoresTable">
                        <TableBody>
                            {scoresData
                                .sort(scoresSort)
                                .map((row) => (
                                    <TableRow key={`FootballScoresRow${row.ID}`}>
                                        <TableCell className={cellClass(row, myPicks)}>
                                            <FootballEventCell 
                                                team1={row.team1} 
                                                team2={row.team2} 
                                                score1={row.score1} 
                                                score2={row.score2} 
                                                started={!!row.started} 
                                                inProgress={!!row.inProgress} 
                                                status={!!row.started ? row.status : null} 
                                                picksWinning={row.picks_winning} 
                                                picksLosing={row.picks_losing}
                                                statusNote={row.statusNote}
                                                possession={row.possession}
                                                notificationSupport={notificationSupport}
                                                notifications={row.notifications}
                                                day={row.day}
                                                dayFormat={dayFormat}
                                                onNotificationUpdate={onNotificationUpdate}
                                                eventId={Number(row.ID)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Fragment>
    );
}
