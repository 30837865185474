import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchP5, postP5, pickString, fixupTime} from "../Utils";
import Select from "@mui/material/Select";
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import {FormControl, InputLabel, TableCell, TextField} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import FootballEventCell from "../components/FootballEventCell";
import TableBody from "@mui/material/TableBody";

function Underdogs(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const fetchUrl = "/api/v1/underdogs/pagedata";
    const [selectedPickId, setSelectedPickId] = useState('0');
    const [selectedUserEntryId, setSelectedUserEntryId] = useState('0');
    const [selectedPickSpread, setSelectedPickSpread] = useState(0);
    const [pickEvents, setPickEvents] = useState([]);
    const [allPicks, setAllPicks] = useState([]);
    const [config, setConfig] = useState([]);

    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'underdogs', props)
            .then(data => {
                if (data.data) {
                    updatePickEvents();
                    updateAllPicks();
                    setConfig(data.data.config.data);
                }
                setIsLoading(false);
            });
    };

    useEffect(() => {
        document.title = "Pick 5 Underdogs";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        fetchData();
    }, []);

    function updateAllPicks() {
        const data = props.getState().underdogs.data;
        let allPicks = data.users.data.map((user) => {
            return {
                'EntryName': user.entry_name,
                'Picks': data.picks.data.filter((pick) => pick.entry_id === user.entry_id)
            };
        });
        setAllPicks(allPicks);
    }
    
    function updatePickEvents() {
        const data = props.getState().underdogs.data;
        let pickEvents = data.users.data.filter((u) => u.pick).map((user) => {
            let team1, team2;
            // Create an event object based on the football_scores record if available, otherwise the football_event record.
            // Both objects should have the same properties.
            let pickScore = data.scores.data.find((ev) => +ev.ID === user.event_id);
            if (!pickScore) {
                pickScore = data.events.data.find((ev) => +ev.ID === user.event_id);
            }
            if (user.pick === pickScore.team1) {
                team1 = pickScore.team1 + " +" + user.spread;
                team2 = pickScore.team2;
            } else {
                team1 = pickScore.team1;
                team2 = pickScore.team2 + " +" + user.spread;
            }
            return {
                'entry_name': user.entry_name,
                'event_id': user.event_id,
                'date': pickScore.day,
                'team1': team2,
                'team2': team1,
                'rank1': pickScore.Rank2,
                'rank2': pickScore.Rank1,
                'score1': pickScore.score2,
                'score2': pickScore.score1,
                'tv': pickScore.TV,
                'started': !!pickScore.started,
                'inProgress': !!pickScore.inProgress,
                'status': pickScore.status,
            };
        });
        setPickEvents(pickEvents);
    }
    
    function renderLoading() {
        return <div>Loading</div>;
    }

    function handleUserSelect(event) {
        const entry_id = event.target.value;
        setSelectedUserEntryId(entry_id);
        const user = props.getState().underdogs.data.users.data.find((user) => user.entry_id === entry_id);
        if (user && user.pick) {
            setSelectedPickId(user.event_id);
            setSelectedPickSpread(user.spread);
        } else {
            setSelectedPickId('0');
        }
    }

    function validatePick(pick) {
        const data = props.getState().underdogs.data;
        const entry = selectedUserEntryId;
        const userPicks = [];
        let userData = data.picks.data.filter((uData) => uData.entry_id === entry);  
        userData.forEach((picks) => userPicks.push(picks.pick));

        if(userPicks.includes(pick)){
            return 1;    
        }
        return 0;
    }

    function handlePickSelect(event) {
        setSelectedPickId(event.target.value);
        const pickEvent = props.getState().underdogs.data.events.data.find((ev) => ev.ID === event.target.value);
        if (validatePick(pickEvent.Spread1 > 0 ? pickEvent.Pick1 : pickEvent.Pick2)) {
            setSelectedPickSpread(-999);
            return 1;
        }
        setSelectedPickSpread(pickEvent.Spread1 > 0 ? pickEvent.Spread1 : pickEvent.Spread2);
    }

    function handlePickSave() {
        postP5(props.getState().userName, props.getState().password, '/api/v1/underdogs/savepick', {
            'entryId': selectedUserEntryId,
            'eventId': selectedPickId,
            'spread': selectedPickSpread
        })
            .then(response => response.json())
            .then(() => fetchData());
    }

    function handleSpreadChange(event) {
        setSelectedPickSpread(event.target.value);
    }

    function renderAdminControl(data) {
        return (<Fragment>
            <div className="underdogsAdminContainer">
                <div className="underdogsAdminContainerSelect">
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <InputLabel id="user-select-label" shrink>User</InputLabel>
                        <Select
                            size="small"
                            onChange={handleUserSelect}
                            value={selectedUserEntryId}
                            labelId="user-select-label"
                            label="User"
                        >
                            {[...[{'entry_id': '0', 'entry_name': ''}], ...data.users.data].map((user) => {
                                return <MenuItem key={'user-menuitem-' + user.entry_id}
                                                 value={user.entry_id}>{user.entry_name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="underdogsAdminContainerSelect">
                    <FormControl sx={{m: 1, minWidth: 200}}>
                        <InputLabel id="pick-select-label" shrink>Pick</InputLabel>
                        <Select
                            size="small"
                            value={selectedPickId}
                            onChange={handlePickSelect}
                            error={selectedPickSpread === -999}
                            label="Pick"
                            labelId="pick-select-label"
                        >
                            {[...[{
                                'ID': '0',
                                'Spread1': '',
                                'Pick1': '',
                                'Spread2': '',
                                'Pick2': ''
                            }], ...data.events.data]
                                .map((event) => {
                                    let team, spread;
                                    if (event.Spread1 > 0) {
                                        team = event.team1;
                                        spread = event.Spread1;
                                    } else {
                                        team = event.team2;
                                        spread = event.Spread2;
                                    }
                                    return {'ID': event.ID, 'team': team, 'spread': spread};
                                })
                                .sort((a, b) => (a.team > b.team) ? 1 : ((a.team < b.team) ? -1 : 0))
                                .map((pick) => {
                                    return <MenuItem key={'event-menuitem-' + pick.ID}
                                                     value={pick.ID}>{pick.team} {pick.spread}</MenuItem>
                                })}
                        </Select>
                        {selectedPickSpread === -999 ? (
                            <FormHelperText error={true}>{"You can't pick the same team twice."}</FormHelperText>
                            ) : null}
                    </FormControl>
                </div>
                <FormControl sx={{m: 1, width: 67}}>
                    <TextField
                        inputProps={{inputMode: 'numeric', min: '0', step: '0.5'}}
                        label="Spread"
                        size="small"
                        id="pick-spread-input"
                        value={selectedPickSpread}
                        onChange={handleSpreadChange}
                        InputLabelProps={{shrink: true}}
                    />
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 40}}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handlePickSave}
                        disabled={selectedPickSpread === -999}
                    >
                        Save
                    </Button>
                </FormControl>
            </div>
        </Fragment>)
    }

    function dayFormat(day) {
        return fixupTime(day.getHours(), day.getMinutes());
    }

    function renderPickEvents(pickEvents, allPicks) {
        return (<Fragment>
            <h4>Week {config.week} Picks</h4>
            <div className="UnderdogsPickEventContainer">
            <div>
                <Table className="UnderdogsPickEventsTable">
                    <TableBody>
                    {pickEvents.map((ev) => {
                        return (<TableRow key={'pickEvent'+ev.event_id}>
                            <TableCell>
                                <div style={{
                                    display: 'flex',
                                }}>
                                <div style={{
                                    width: '80px'
                                }}>
                                    <span>{ev.entry_name}</span>
                                </div>
                                <FootballEventCell
                                    team1={ev.team1}
                                    team2={ev.team2}
                                    score1={ev.score1}
                                    score2={ev.score2}
                                    started={ev.started}
                                    inProgress={ev.inProgress}
                                    status={!!ev.started ? ev.status : null}
                                    day={ev.date}
                                    eventId={ev.event_id}
                                    dayFormat={dayFormat}
                                />
                                </div>
                            </TableCell>
                        </TableRow>); 
                    })}
                    </TableBody>
                </Table>
            </div>
            <div className="UnderdogsAllPicksTableContainer">
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell key="allPicksColHeader-0"/>
                            {[...Array(config.week).keys()].map((week) => {
                                return (
                                    <TableCell key={'allPicksColHeader-'+(week+1)}>{week+1}</TableCell>
                                );
                            })}
                        </TableRow>
                        {allPicks.map((userPicks) => {
                            return (<TableRow key={'allPicks-'+userPicks.EntryName}>
                                <TableCell>
                                    <div style={{
                                        display: 'flex',
                                    }}>
                                        <div style={{
                                            width: '80px'
                                        }}>
                                            <span>{userPicks.EntryName}</span>
                                        </div>
                                    </div>
                                </TableCell>
                                {[...Array(config.week).keys()].map((week) => {
                                    const weeklyPick = userPicks.Picks.find((pick) => pick.week === week+1);
                                    const pString = weeklyPick ? pickString(weeklyPick.pick, weeklyPick.spread) : '';
                                    const className = (weeklyPick && weeklyPick.result === 1) ? 'footballEventWinner' : (weeklyPick && weeklyPick.result === 0) ? 'footballEventLoser' : '';
                                    return (<TableCell className={className} key={'underdogPickCell-'+userPicks.EntryName+week}>{pString}</TableCell>);
                                })}
                            </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </div>
            </div>
        </Fragment>);
    }
    
    function renderUnderdogsData() {
        return (<Fragment>
            {config.isAdmin ? renderAdminControl(props.getState().underdogs.data) : ''}
            {renderPickEvents(pickEvents, allPicks)}
        </Fragment>);
    }
    
    return (
        <div>
            <h3>
                Underdogs
            </h3>
            {isLoading ? renderLoading() : renderUnderdogsData()}
        </div>
    );
}

export default Underdogs;